$(document).ready(function(){
	// Плавная прокрутка страницы
    $(".menu").on("click","a", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),     
            top = $(id).offset().top;     
        $('body,html').animate({scrollTop: top}, 1000);
    }); 
    // Плавающая шапка
    var options = {
	  offset: 650
	}

    var header = new Headhesive('.menu', options);
    // Всплывающнн окно

    $('.box-product-2__btn').click(function(){
	    $('#exampleModal-2').arcticmodal();
	});
	$('.btn-down').click(function(){
	    $('#exampleModal-3').arcticmodal();
	});

    // перезагрузка страницы
	  $(".btn").click(function(){
         setTimeout(
         function() {
             location.reload();
        }, 3000
        );
        });


    // Форма отправки данных

	  $('[data-submit]').on('click', function(){    	
        $(this).parent('form').submit();
    })
	    $.validator.addMethod(
	        "regex",
	        function(value, element, regexp) {
	            var re = new RegExp(regexp);
	            return this.optional(element) || re.test(value);
	        },
	        "Please check your input."
	    );
	    function valEl(el){

	        el.validate({
	            rules:{
	                tel:{
	                    required:true,
	                    regex: '^([\+]+)*[0-9\x20\x28\x29\-]{5,20}$'
	                },
	                name:{
	                    required:true
	                },
	                email:{
	                    required:true,
	                    email:true
	                }
                },
	            messages:{
	                tel:{
	                    required:'Поле обязательно для заполнения',
	                    regex:'Телефон может содержать символы + - ()'
	                },
	                name:{
	                    required:'Поле обязательно для заполнения',
	                },
	                email:{
	                    required:'Поле обязательно для заполнения',
	                    email:'Неверный формат E-mail'
	                }	
	            },
	            
	            submitHandler: function (form) {
	                $('#overlay').fadeIn();
	                var $form = $(form);				
	                var $formId = $(form).attr('id');
	                switch($formId){
	                    case'goToNewPage':
	                        $.ajax({
	                            type: 'POST',
	                            url: $form.attr('action'),
	                            data: $form.serialize(),                         
	                        })
	                        $("form")[0].reset();
	                        
	                        break;
	                    

	                }
	                return false;
	            }
	        })
	    }
    $('.form-head').each(function() {
        valEl($(this));
    });    

});